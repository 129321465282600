
import { defineComponent, ref } from "vue";
import DocumentService from "@/core/services/DocumentService";
import { DOCUMENT_TYPES } from "@/core/config/Constant";

export default defineComponent({
  name: "DocumentSummary",
  props: {
    id: {type: String},
    type: {type: String},
  },
  setup(props) {
    const summaries = ref([])
    const fetchData = async (id, type) => {
      await DocumentService.summary<any>(id, type).then(res => {
        summaries.value = res
      })
    }

    const loadData = () => {
      fetchData(props.id, props.type)
    }
    loadData()
    return {
      loadData,
      summaries,
      DOCUMENT_TYPES,
    }
  }
})
