
import {defineComponent, onUpdated, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import DocumentService from "@/core/services/DocumentService";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import {DOCUMENT_STATUS, DOCUMENT_TYPES} from "@/core/config/Constant";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import Error from "@/components/base/Error.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";

export default defineComponent({
  name: "UploadForm",
  components: {UserListSelect, Error, BaseRadio, BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    isAudio: {default: false},
    documentId: {type: String},
    cazeId: {type: String},
    activityId: {type: String},
    claimId: {type: String},
    invoiceId: {type: String},
    policyId: {type: String},
    productId: {type: String},
    processId: {type: String},
    logReportId: {type: String},
    documentType: {type: String, default: 'LETTER'},
    name: {type: String, default: ''},
    status: {type: String, default: 'RECEIVED'},
    isPending: {type: Boolean, default: false},
    area: {type: String, default: () => 'GENERIC'}
  },
  emits: ['onUploaded'],
  setup(props, {emit}) {
    const submitting = ref(false);
    const uploadFile = ref({
      cazeId: ref(props.cazeId).value,
      file: '',
      activityId: ref(props.activityId).value,
      claimId: ref(props.claimId).value,
      invoiceId: ref(props.invoiceId),
      policyId: ref(props.policyId),
      productId: ref(props.productId),
      documentType: ref(props.documentType),
      processId: ref(props.processId),
      logReportId: ref(props.logReportId),
      name: props.name,
      status: ref(props.status).value,
      isPending: ref(props.isPending).value,
      area: ref(props.area),
      notifies: []
    })

    const errorLogs = ref({show: false, message: ''})

    watch(() => props.activityId, (cb: any) => {
      uploadFile.value.activityId = cb;
    })

    watch(() => props.cazeId, (cb: any) => {
      uploadFile.value.cazeId = cb;
    })
    watch(() => props.logReportId, (cb: any) => {
      uploadFile.value.logReportId = cb;
    })
    watch(() => props.claimId, (cb: any) => {
      uploadFile.value.claimId = cb;
    })
    watch(() => props.processId, (cb: any) => {
      uploadFile.value.processId = cb;
    })
    watch(() => props.documentType, (cb: any) => {
      console.log(props.documentType);
      uploadFile.value.documentType = cb;
    })
    watch(() => props.policyId, (cb: any) => {
      uploadFile.value.policyId = cb;
    })
    watch(() => props.productId, (cb: any) => {
      uploadFile.value.productId = cb;
    })
    watch(() => props.name, (cb: any) => {
      uploadFile.value.name = cb;
    })
    watch(() => props.status, (cb: any) => {
      uploadFile.value.status = cb;
    })
    watch(() => props.isPending, (cb: any) => {
      uploadFile.value.isPending = cb;
    })
    const handleFileChange = (e: any) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = e.target.files[0]
      uploadFile.value.name = file.name;
      uploadFile.value.file = e.target.files[0];
    }

    onUpdated(() => {
      const doc = document.getElementById("file-type")
      if (doc) (doc as HTMLInputElement).value = ""
    })

    const upload = () => {
      submitting.value = true
      if (props.documentId) {
        DocumentService.edit(props.documentId, uploadFile.value).then(res => {
          uploadFile.value.file = ''
          uploadFile.value.documentType = 'LETTER'
          uploadFile.value.name = ''
          uploadFile.value.status = 'RECEIVED'
          uploadFile.value.isPending = false;
          errorLogs.value.show = false
          errorLogs.value.message = ''
          emit('onUploaded', res)
        }).catch(error => {
          const data = error.response.data;
          console.log(data);
          errorLogs.value.message = data.message
          errorLogs.value.show = true;
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        DocumentService.upload(uploadFile.value).then(res => {
          uploadFile.value.file = ''
          uploadFile.value.documentType = 'LETTER'
          uploadFile.value.name = ''
          uploadFile.value.notifies = [];
          uploadFile.value.status = 'RECEIVED'
          uploadFile.value.isPending = false;
          errorLogs.value.show = false
          errorLogs.value.show = false
          errorLogs.value.message = ''
          emit('onUploaded', res)
        }).catch(error => {
          const data = error.response.data;
          errorLogs.value.message = data.message
          errorLogs.value.show = true;
        }).finally(() => {
          submitting.value = false;
        })
      }

    }
    return {
      submitting,
      handleFileChange,
      upload,
      errorLogs,
      uploadFile,
      DOCUMENT_TYPES,
      DOCUMENT_STATUS,
    }
  }
})
