
import {computed, defineComponent, onRenderTracked, onUpdated, ref,} from "vue";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {DocumentDetail, HandlePanel} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import store from "@/store";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import UploadForm from "@/views/document/UploadForm.vue";
import DocumentService from "@/core/services/DocumentService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Panel from "@/components/base/panel/Panel.vue";
import FormNote from "@/views/activity/FormNote.vue";
import Audits from "@/views/audit/Audits.vue";
import SentDocuments from "@/views/document/SentDocuments.vue";
import ReceivedDocuments from "@/views/document/ReceivedDocuments.vue";
import Notes from "@/views/note/Notes.vue";
import FormRename from "@/views/document/FormRename.vue";
import AllDocuments from "@/views/document/AllDocuments.vue";
import DocumentSummary from "@/views/document/DocumentSummary.vue";
import FileShares from "@/views/fileshare/FileShares.vue";
import FileShareForm from "@/views/fileshare/FileShareForm.vue";
import PendingDocuments from "@/views/document/PendingDocuments.vue";
import DocumentNotifyForm from "@/views/document/DocumentNotifyForm.vue";
import {useToast} from "vue-toast-notification";
import DocumentConfirm from "@/views/document/DocumentConfirm.vue";
import CustomerDocumentStatus from "@/views/document/CustomerDocumentStatus.vue";

export default defineComponent({
  name: "Documents",
  components: {
    CustomerDocumentStatus,
    DocumentConfirm,
    DocumentNotifyForm,
    PendingDocuments,
    FileShareForm,
    FileShares,
    DocumentSummary,
    AllDocuments,
    FormRename,
    Notes,
    ReceivedDocuments,
    SentDocuments,
    Audits,
    FormNote, Panel, UploadForm, BaseModal, QuickAction
  },
  setup(props) {
    const today = new Date();
    const expiredDate = new Date(today.setDate(today.getDate() + 3));
    const refTpe = ref(props.type)
    const refId = ref(props.id)
    const note = ref({text: '', documentId: ''})

    const model = ref({
      documentId: '',
      documentType: 'LETTER',
      status: 'PROCESSED',
      isPending: false,
      name: '',
      claimId: '',
      cazeId: '',
      invoiceId: '',
      policyId: '',
      logReportId: '',
      productId: '',
      processId: '',
      area: props.area
    })
    const rename = ref({
      documentId: '', name: ''
    })

    switch (refTpe.value) {
      case 'CASE':
        model.value.cazeId = refId.value
        break
      case 'CLAIM':
        model.value.claimId = refId.value
        break
      case 'INVOICE':
        model.value.invoiceId = refId.value
        break
      case 'POLICY':
        model.value.policyId = refId.value
        break
      case 'PRODUCT':
        model.value.productId = refId.value
        break;
      case 'PROCESS':
        model.value.processId = refId.value
        break
      case 'LOG_REPORT':
        model.value.logReportId = refId.value
        break
    }

    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    const documentEntity = computed(() => store.state.DocumentModule.document);
    const documentUrl = computed(() => store.state.DocumentModule.documentUrl);
    const allPage = computed(() => store.state.DocumentModule.allPage);
    const sentPage = computed(() => store.state.DocumentModule.sentPage);
    const receivedPage = computed(() => store.state.DocumentModule.receivedPage);
    const pendingPage = computed(() => store.state.DocumentModule.pendingPage);
    const access = computed(() => store.state.DocumentModule.access);
    const product = computed(() => store.state.ProductModule.product);
    return {
      pendingPage,
      ...HandlePanel(Actions.LOAD_DOCUMENT_URL),
      documentEntity,
      ...DocumentDetail(),
      note,
      documentUrl,
      model,
      rename,
      allPage,
      sentPage,
      receivedPage,
      expiredDate,
      access,
      product,
    }
  },

  props: {
    type: {required: true, type: String},
    id: {required: true, type: String},
    area: {type: String, default: () => 'GENERIC'}
  },

  methods: {

    notify(documentId) {
      const baseModal = this.$refs.formNotifyDocumentRef as typeof BaseModal
      baseModal.showBaseModal()
      this.rename.documentId = documentId
    },

    notified() {
      const baseModal = this.$refs.formNotifyDocumentRef as typeof BaseModal
      baseModal.hideBaseModal()
      useToast().success('Notify successfully', {position: 'top-right'})
    },

    share() {
      const baseModal = this.$refs.addFileShareRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    shared() {
      const baseModal = this.$refs.addFileShareRef as typeof BaseModal
      baseModal.hideBaseModal()
      const files = this.$refs.refFileShares as typeof FileShares
      files.paginationLoad()
    },
    savedRename() {
      const baseModal = this.$refs.renameDocumentRef as typeof BaseModal
      baseModal.hideBaseModal()
      this.reload()
    },
    uploaded(res: any) {
      const baseModal = this.$refs.uploadDocumentRef as typeof BaseModal
      baseModal.hideBaseModal()
      if (this.type === 'LOG_REPORT') {
        store.commit(Mutations.SET_FILE_DATE, res?.logReport?.reportDate)
        store.commit(Mutations.SET_FILE_NEXT_DATE, res?.logReport?.nextDate)
      }
      this.reload()
    },

    onSaveNote(res: any) {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal()
      const notes = this.$refs.refNotes as typeof Notes
      notes.paginationLoad();
      this.loadDocDetail(res);
    },

    reload() {
      const sentDocumentsRef = this.$refs.sentDocumentsRef as typeof SentDocuments
      sentDocumentsRef.paginationLoad()
      const receivedDocumentsRef = this.$refs.receivedDocumentsRef as typeof ReceivedDocuments
      receivedDocumentsRef.paginationLoad()
      const pendingDocumentsRef = this.$refs.pendingDocumentsRef as typeof PendingDocuments
      pendingDocumentsRef.paginationLoad()
      const allDocuments = this.$refs.allDocuments as typeof AllDocuments
      allDocuments.paginationLoad()
      const documentSummary = this.$refs.documentSummary as typeof DocumentSummary;
      documentSummary.loadData();
    },

    onRename(document: any) {
      this.rename.documentId = document.id;
      this.rename.name = document.name
      const baseModal = this.$refs.renameDocumentRef as typeof BaseModal
      baseModal.showBaseModal()
    },


    onNew() {
      const baseModal = this.$refs.uploadDocumentRef as typeof BaseModal
      baseModal.showBaseModal()
      this.model.name = '';
      if (this.type === 'LOG_REPORT') {
        this.model.documentType = 'REPORT';
      }
      this.model.status = 'RECEIVED';
      this.model.isPending = false;
      this.model.documentId = '';
      this.model.area = this.area
    },
    async onEdit(id) {
      await DocumentService.get<any>(id).then(res => {
        const baseModal = this.$refs.uploadDocumentRef as typeof BaseModal
        baseModal.showBaseModal()
        this.model.name = res.name;
        this.model.status = res.status.code;
        this.model.documentType = res.documentType.code;
        this.model.documentId = res.id;
        this.model.isPending = res.isPending;
        this.model.area = res.area
      })

    },

    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await DocumentService.delete(id).then(() => {
            this.reload()
          })
        }
      })
    },
    onConfirm(doc) {
      this.note.documentId = doc.id;
      const modal = this.$refs.confirmDocumentRef as typeof BaseModal
      modal.showBaseModal();
    },
    onConfirmed() {
      const modal = this.$refs.confirmDocumentRef as typeof BaseModal
      modal.hideBaseModal();
      this.reload()

    },
    onShowPanel(id) {
      this.loadPanelData(id);
    },
    onDownloadPdf(id) {
      Swal.showLoading()
      DocumentService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        Swal.close()
      })
    }
  }
})
