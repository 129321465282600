
import {computed, defineComponent, ref} from "vue";
import store from "../../store";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {DOCUMENT_TYPES} from "@/core/config/Constant";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Pagination from "@/components/base/paging/Pagination.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DocumentIcon from "@/views/document/DocumentIcon.vue";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import CustomerDocumentStatus from "@/views/document/CustomerDocumentStatus.vue";

export default defineComponent({
  name: "PendingDocuments",
  components: {
    CustomerDocumentStatus,
    EntityLoading, BaseSelect, BaseCheckBox, DocumentIcon, Avatar, Pagination, QuickAction, DateTimeFormat
  },
  props: {
    type: {required: true, type: String},
    id: {required: true, type: String},
    area: {type: String, default: () => 'GENERIC'},
  },
  emits: ['delete', 'download', 'showDetail', 'edit', 'rename', 'panel'],
  setup(props) {
    const refTpe = ref(props.type)
    const refId = ref(props.id)
    const filterObject = ref({
      productId: '',
      cazeId: '',
      claimId: '',
      invoiceId: '',
      policyId: '',
      processId: '',
      logReportId: '',
      status: 'RECEIVED',
      documentType: '',
      notType: 'AUDIO',
      includeCase: false,
      area: props.area,
      noFilterPending: false,
      isPending: true,
    })
    switch (refTpe.value) {
      case 'CASE':
        filterObject.value.cazeId = refId.value
        break
      case 'CLAIM':
        filterObject.value.claimId = refId.value
        break
      case 'INVOICE':
        filterObject.value.invoiceId = refId.value
        break
      case 'POLICY':
        filterObject.value.policyId = refId.value
        break
      case 'PRODUCT':
        filterObject.value.productId = refId.value
        break
      case 'PROCESS':
        filterObject.value.processId = refId.value
        break
      case 'LOG_REPORT':
        filterObject.value.logReportId = refId.value
        break
    }
    const page = computed(() => store.state.DocumentModule.pendingPage);
    const access = computed(() => store.state.DocumentModule.access);
    return {
      ...LoadFilterObjects(Actions.LOAD_PENDING_DOCUMENTS, filterObject.value, ['createdBy']),
      page,
      filterObject,
      DOCUMENT_TYPES,
      ...LoadPanel(),
      access,
    }
  },
  methods: {
    deletedDoc(id) {
      this.$emit("delete", id)
    },
    onDownloadPdf(id) {
      this.$emit("download", id)
    },
    showDetail(id) {
      this.$emit("showDetail", id)
    },
    onEdit(id) {
      this.$emit("edit", id)
    },
    rename(doc) {
      this.$emit("rename", {name: doc.name, id: doc.id})
    },
    onPreview(id) {
      this.$emit("panel", id)
    },
    onFilter(documentType) {
      this.filterObject.documentType = documentType;
      this.updateFilterObject(this.filterObject);
    },
    onUpdateFilter() {
      this.updateFilterObject(this.filterObject);
    }
  }
})
